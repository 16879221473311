div#hero-section {
    padding-top: 12px;
    text-align: center;
    background: url('../images/hero-bg.png') center bottom no-repeat;
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;

    div.welcome-h1 {
        font-family: philosopher,sans-serif;
        font-weight: 700;
        font-size: 32px;
        color: #5ca24d;
        margin: 10px auto;
    }
    div.welcome-h2 {
        font-family: roboto,sans-serif;
        margin: 0px 20px 20px 20px;
        font-size: 18px;
        font-weight: 300;
    }
    div.welcome-h3 {
        font-family: roboto,sans-serif;
        margin: 15px 20px 0px 20px;
        font-size: 16px;
        font-weight: 300;
    }
    img#hero-img {
        max-width: 95%;
        margin-top: 5px;
    }
    div.notes {
        margin: 5px 20px 20px 20px;
        font-size: 15px;
        font-weight: 300;
        color: #777;
    }
    div.bottom-margin {
        height: 40px;
    }
}

div.flex-container {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;

    div.feature-block {
        min-width: 280px;
        min-height: 360px;
        margin: 10px;
        padding: 20px;
        background: #fff none repeat scroll 0 0;
        box-shadow: 0 0 20px rgba(20, 20, 20, 0.1);
        div.welcome-p {
            font-family: roboto,sans-serif;
            text-align: left;
            margin-top: 20px;
            font-size: 18px;
            font-weight: 300;
            line-height: 1.4;
        }
        img.fimage {
            width: 160px;
            height: 160px;
        }
    }
    div.feature-block:hover {
        box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
        transition: all 0.5s ease-in-out 0s;
    }
}

// Extra small devices (phones, 400px and down)
@media only screen and (max-width: 400px) {
}

// Small devices (phones, 600px and down)
@media only screen and (max-width: 600px) {
    div.flex-container >div.feature-block {
        width: 95%;
        min-height: 260px;
    }
}

// Small devices (portrait tablets and large phones, 600px and up)
@media only screen and (min-width: 600px) {
    div.hero-section {
        div.welcome-h1 { font-size: 36px }
        div.bottom-margin {
            height: 60px;
        }
    }
    div.flex-container >div.feature-block {
        width: 90%;
        min-height: 280px;
        img.fimage {
            width: 180px;
            height: 180px;
        }
    }
}

// Medium devices (landscape tablets, 768px and up)
@media only screen and (min-width: 768px) {
    div.hero-section {
        div.welcome-h1 { font-size: 40px }
        div.welcome-h2 { font-size: 20px }
        div.welcome-h3 { font-size: 18px }
        div.bottom-margin {
            height: 100px;
        }
    }
    div.flex-container >div.feature-block {
        width: 40%;
        min-height: 300px;
        img.fimage {
            width: 160px;
            height: 160px;
        }
    }
}

// Large devices (laptops/desktops, 992px and up)
@media only screen and (min-width: 992px) {
    div.hero-section {
        div.welcome-h1 { font-size: 42px }
        div.welcome-h2 { font-size: 22px }
        div.welcome-h3 { font-size: 20px }
        div.bottom-margin {
            height: 120px;
        }
    }
    div.flex-container >div.feature-block {
        width: 30%;
        min-height: 320px;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
}

// Extra large devices (large laptops and desktops, 1200px and up)
@media only screen and (min-width: 1200px) {
    div.hero-section {
        div.welcome-h1 {
            font-size: 48px
        }
        div.bottom-margin {
            height: 140px;
        }
    }
    div.flex-container >div.feature-block {
        width: 20%;
        min-height: 340px;
        img.fimage {
            width: 180px;
            height: 180px;
        }
    }
}