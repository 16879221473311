.calendar-container {
    float: left;
    position: absolute;
    width: 270px;
    height: 310px;
    background-color: white;
    border: 1px solid #b0b0b0;
    box-shadow: 3px -3px 6px rgba(120,120,120,0.4);
    z-index: 20;

    div.cheader {
        width: 100%;
        color: #202020;
        display: inline-flex;
        align-items: center;

        .ymbtn {
            margin: 6px 4px;
            padding: 6px 1px;
            width: 92px;
            text-align: center;
            transition: .5s background-color;
            cursor: pointer;
        }

        .ymbtn:hover{
            background-color:#e0e0e0;
        }

        button.arrow {
            float: left;
            padding: 0 6px;
            border-style: solid;
            background-color: transparent;
            cursor: pointer;

            &.right {
                border-width: 8px 0px 8px 10px;
                border-color: transparent transparent transparent #666;
            }
            &.right[disabled] {
                cursor: default;
                opacity: 0.5;
            }
            &.left {
                border-width: 8px 10px 8px 0px;
                border-color: transparent #666 transparent transparent;
            }
            &.left[disabled] {
                cursor: default;
                opacity: 0.5;
            }
        }
        div.today {
            margin: 6px 4px;
            width: 60px;
        }
        div.empty-div {
            flex-grow: 1;
        }
    }

    div.dates {
        width: 100%;

        .days {
            color: #808080;
            padding: 5px 10px;
            div {
                display: inline-block;
                margin: 1px;
                width: 33px;
                height: 33px;
                line-height: 33px;
                text-align: center;
                vertical-align: middle;
                box-sizing: border-box;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                cursor: default;
            }
            .round {
                color: #505050;
                border-radius: 50%;
            }
            .round:hover {
                background-color: #f0f0f0;
                cursor: pointer;
            }
            .round:active {
                color: white;
                background-color: #00aaff;
            }
            .day-gray {
                color: #b0b0b0;
                cursor: default;
            }
            .current-day {
                border: 1px solid #4984f2;
            }
            .chosen-date {
                background-color: #e0e0e0;
            }
        }
        .dheader {
            border-bottom: 1px solid #c0c0c0;
        }
    }

    div.months {
        border-top: 1px solid #c0c0c0;
        max-height: 266px;
        overflow-x: hidden;
        overflow-y: auto;

        div.m-year {
            margin: 5px 10px 2px 10px;
            color: #b0b0b0;
            font-weight: bold;
            cursor: default;
        }
        div.m-months {
            margin: 0px 10px 0px 20px;
            div {
                display: inline-block;
                margin: 1px;
                width: 56px;
                height: 28px;
                line-height: 28px;
                text-align: center;
                vertical-align: middle;
                color: #505050;
                cursor: pointer;
            }
            div:hover {
                background-color: #f0f0f0;
                cursor: pointer;
            }
            div.selected {
                color: white;
                background-color: #00aaff;
            }
        }
    }

    .clear {
        clear:both;
    }
}

input.calendar-inputbox {
    display: inline-block;
    font-family: inherit;
    font-size: inherit;
    text-align: center;
    width: 100px;
    height: 36px;
    margin: auto 2px;
    cursor: pointer;
    border: 1px solid #b1b1b1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    &:hover {
        box-shadow: 0 0 3px 0 #a0a0a0;
        border-color: #a0a0a0;
        outline: none;
    }
    &:focus {
        box-shadow: 0 0 3px 0 #4984f2;
        border-color: #4984f2;
        outline: none;
    }
}
