/**
 * tab widget
 * Usage:
 * <div class="tab">
 *     <button>Tab1</button>
 *     <button>Tab2</button>
 * </div>
 */
div.tab {
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #f0f0f0;
    button {
        background-color: white;
        float: left;
        border: none;
        outline: none;
        cursor: default;
        padding: 10px 20px;
        transition: 0.3s;
        font-size: 12pt;
        &:not([disabled]):hover {
            cursor: pointer;
            background-color: #ddd;
        }
        &:not([disabled]):active, &.selected {
            color: white;
            background-color: #0374f1;
            cursor: default;
        }
    }
    &.tabcontent {
        display: none;
        padding: 6px 12px;
        border: 1px solid #ccc;
        border-top: none;
    }
}


/**
 * regular button style
 * Usage:
 * <button class="regular">Button Label</button>
 */
button {
    &.regular {
        width: 120px;
        height: 36px;
        border:  1px solid #ccc;
        font-size: 12pt;
        background-color: #f0f0f0;
        cursor: default;
    }
    &.regular:not([disabled]):hover {
        color: white;
        background-color: #0374f1;
        border-color: #808080;
        cursor: pointer;
    }
    &.regular:not([disabled]):active {
        color: white;
        background-color: #0374f1;
        cursor: default;
    }
}

/**
 * toggle switch widget
 * https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_switch
 *
 * Usage:
 * <label class="toggle-status">
 *     <input type="checkbox" />
 *     <span class="slider"></span>
 * </label>
 */
 label.toggle-status {
    position: relative;
    display: inline-block;
    width: 72px;
    height: 30px;
    border: 1px solid #b1b1b1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    >input {
        opacity: 0;
        width: 0;
        height: 0;
        &.active:not(:checked) + .slider {
            background-color: #f7cac9; // #ec0606;
        }
        &:checked + .slider {
            background-color: #0374f1; // #4ca772;
        }
        &:checked + .slider:before {
            content: "Yes";
            -webkit-transform: translateX(32px);
            -ms-transform: translateX(32px);
            transform: translateX(32px);
        }
    }
    .slider {
        position: absolute;
        cursor: pointer;
        text-align: center;
        vertical-align: middle;
        line-height: 22px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        &:before {
            position: absolute;
            content: 'No';
            height: 22px;
            width: 32px;
            left: 3px;
            bottom: 3px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
        }
    }
}

/**
 * toggle switch widget
 * https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_switch
 *
 * Usage:
 * <label class="toggle-switch">
 *     <input type="checkbox" />
 *     <span class="slider"></span>
 * </label>
 */
 label.toggle-switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;

    >input {
        opacity: 0;
        width: 0;
        height: 0;
        &:checked + .slider {
            background-color: #4984f2;
        }

        &:focus + .slider {
            box-shadow: 0 0 1px #4984f2;
        }

        &:checked + .slider:before {
            -webkit-transform: translateX(20px);
            -ms-transform: translateX(20px);
            transform: translateX(20px);
        }
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 26px;
        &:before {
            position: absolute;
            content: "";
            height: 20px;
            width: 20px;
            left: 2px;
            bottom: 2px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 50%;
        }
    }
}


/**
 * checkbox widget
 * Usage:
 * <label class="checkbox">
 *     <input type="checkbox" />
 *     <span class="checkmark"></span>
 * </label>
 */
label.checkbox {
    display: inline-block;
    vertical-align: middle;
    margin: auto 5px auto 0;
    height: 18px;
    cursor: pointer;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        &:checked ~ .checkmark {
            background-color: #4984f2;
            &:before {
                // content: '\2714';
                content: '✔\fe0e';
                font-size: 9pt;
                text-align: center;
                background-color: #4984f2;
            }
        }
    }
    .checkmark {
        position: relative;
        display: inline-block;
        width: 18px;
        height: 18px;
        background-color: #ccc;
        &:before {
            content: '';
            position: absolute;
            top: 2px;
            left: 2px;
            width: 14px;
            height: 14px;
            color: white;
            background-color: white;
        }
    }
    &.largebox {
        height: 24px;
        margin: auto 5px;
        >input:checked ~ .checkmark:before {
            color: #4984f2;
            font-size: 12pt;
            background-color: white;
        }
        .checkmark {
            width: 24px;
            height: 24px;
            &:before {
                width: 20px;
                height: 20px;
            }
        }
    }
}

/**
 * radio button widget
 * Usage:
 * <label class="radio-button">
 *     <input type="radio" name="radio" />
 *     <span class="radio"></span>
 * </label>
 */
label.radio-button {
    display: inline-block;
    vertical-align: middle;
    margin: auto 5px;
    height: 20px;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        &:checked ~ .radio:before {
            display: block;
        }
    }
    span.radio {
        position: relative;
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 2px solid #ccc;
        &:before {
            content: '';
            position: absolute;
            top: 3px;
            left: 3px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: none;
            background-color: #4984f2;
        }
    }
}
