div.modal-bg, div.alert-bg {
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    z-index: 199;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.5);

    div.modal-popup {
        position: relative;
        margin: 10px;
        background-color: #fefefe;
        border: 1px solid #888888;
        min-width: 320px;
        min-height: 250px;
        box-shadow: 4px 4px 2px #666666;

        div.modal-title {
            width: 100%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            color: #888888;
            font-size: 16pt;
            border-bottom: 1px solid #cccccc;
        }
        div.modal-content {
            overflow: auto;
            min-height: 200px;
        }
        div.alert-body {
            padding: 10px 10px 60px 10px;
            overflow: auto;
            min-height: 120px;
            max-height: 300px;
            >a {
                text-decoration: none;
                color: #0374f1;
            }
            >b, >i {
                color: red;
            }
        }
        div.alert-actions {
            position: absolute;
            bottom: 10px;
            width: 100%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            button.alert-button {
                background-color: #f0f0f0;
                border:  1px solid #cccccc;
                width: 120px;
                height: 40px;
                text-align: center;
                font-size: 16px;
                margin: 1px 5px;
                cursor: pointer;
            }
        }
        >div.nav-btn {
            position: absolute;
            width: 24px;
            height: 24px;
            cursor: pointer;
            &.close {
                background-position: -280px -90px;
                top: -10px;
                right: -10px;
            }
        }
    }
    &.start {
        align-items: flex-start;
    }
}

// Small devices (phones, 600px and down)
@media only screen and (max-width: 600px) {
    div.modal-bg >div.modal-popup {
        width: 100%;
        >div.nav-btn.close {
            background-position: -240px -90px;
            top: 13px;
            left: 10px;
        }
    }
}
