header#top-bar {
    left: 0px;
    top: 0px;
    right: 0px;
    position: fixed;
    z-index: 3;
    transition: all 0.5s ease;
    background: #fff;
    border-bottom: 1px solid #d2d2d7;

    nav.topnav {
        display: flex;
        align-items: center;
        position: relative;
        height: 48px;
        color: #777;

        div.hamburg-menu {
            width: 24px;
            height: 24px;
            background-position: -120px -90px;
            margin-left: 10px;
        }
        img.avatar {
            border-radius: 50%;
            cursor: pointer;
            margin: auto 10px auto 0px;
            width: 32px;
            height: 32px;
        }
        div.top-menu >ul {
            >li {
                cursor: pointer;
                a {
                    color: #777;
                    text-decoration: none;
                }
                a.selected {
                    cursor: default;
                }
                div.arrow-down {
                    width: 12px;
                    height: 12px;
                    background-position: -360px -90px;
                }
            }
            >li:has(>a.selected) {
                cursor: default;
            }
        }
        div.sub-menu {
            position: absolute;
            top: 100%;
            max-height: 0px;
            overflow: hidden;
            transition: max-height 0.4s;
            box-shadow: -2px 2px 3px #c0c0c0;

            // Avatar SubMenu Content
            div.asm-content {
                padding: 20px 0;
                background-color: #fff;
                border: 1px solid #e0e0e0;
                hr {
                    border: 0;
                    height: 1px;
                    width: 100%;
                    background: #CCCCCC;
                    margin: 15px 0 10px;
                }
                .link {
                    color: #777;
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    margin: 12px 0;
                    cursor: pointer;

                    >.icon {
                        width: 18px;
                        height: 18px;
                        margin: 10px 20px 10px 15px;
                    }
                    >.uprofile {
                        background-position: 0 -90px;
                    }
                    >.logout {
                        background-position: -40px -90px;
                    }
                    p {
                        flex: 1;
                        font-size: 16px;
                    }
                }
                .link:hover {
                    background-color: #d7f1fd;
                }
            }
        }
        div.sub-menu.avatar-sm {
            right: 2px;
            min-width: 280px;
        }
        div.top-menu.open-submenu, div.sub-menu.open-submenu {
            max-height: 600px;
        }
    }
}
header#top-bar.scroll-header {
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
}

main.main-content {
    div.general-msg {
        width: 400px;
        margin: 0px auto;
        padding: 20px 0px;
        text-align: center;
        p {
            font-size: 13pt;
        }
    }
    &.with-hdft {
        margin-top: 48px;
        min-height: -webkit-calc(100vh - 105px);
        min-height: -moz-calc(100vh - 105px);
        min-height: calc(100vh - 105px);
    }
    &.without-hdft {
        min-height: 100vh;
    }
    &.vcenter {
        display: flex;
        align-items: center;
    }
    &.scheme-mm {
        color: #404040;
        background-color: #EAEAEA;
    }
    &.scheme-pb {
        color: #d0d0d0;
        background-color: #303030;
    }
}

footer >div.legal {
    position: relative;
    font-size: 13px;
    text-align: center;
    padding: 20px 10px;

    .copyright {
        font-weight: lighter;
        padding-right: 40px;
        color: #868686;
        white-space: nowrap;
    }
    .links {
        white-space: nowrap;
        a {
            font-weight: lighter;
            margin-right: 20px;
            text-decoration: none;
            color: #0374f1;
        }
        a:hover {
            text-decoration: underline;
        }
        a.privacy {
            margin-right: 7px;
            padding-right: 10px;
            border-right: 1px solid #d2d2d7;
        }
    }
}
footer.footer-full {
    border-top: 1px solid #d2d2d7;
}
footer.footer-auth >div.legal {
    padding: 5px 5px 30px 0px;;
    text-align: right;

    .links a {
        margin: 0px 0px 0px 20px;
    }
}

// Extra small devices (phones, 400px and down)
@media only screen and (max-width: 400px) {
}

// Small devices (phones, 600px and down)
@media only screen and (max-width: 600px) {
    header#top-bar nav.topnav {
        div.pm-brand {
            flex: 1
        }
        div.top-menu >ul {
            display: flex;
            flex-direction: column;
            border: 1px solid #e0e0e0;
            padding-inline-start: 0;
            margin: 0;
            >li {
                text-align: left;
                margin: 10px 0;
                padding: 8px 5px 8px 20px;
                a.selected {
                    color: #58A55C;
                }
                div.arrow-down {
                    display: inline-block;
                    margin-left: 5px;
                    background-position: -360px -88px;
                }
                ul.sub-menu {
                    padding-inline-start: 10px;
                    max-height: 600px;
                    >li {
                        margin-top: 10px;
                        padding: 10px 5px 10px 20px;
                        list-style-type: none;
                        cursor: pointer;
                        a.selected {
                            color: #58A55C;
                            cursor: default;
                        }
                    }
                    >li:hover {
                        background-color: #d7f1fd;
                    }
                    >li:has(>a.selected) {
                        cursor: default;
                    }
                }
                &:hover {
                    background-color: #d7f1fd;
                }
                &.has-submenu {
                    margin-bottom: 0;
                    padding: 10px 0 0 20px;
                    &:hover {
                        background-color: inherit;
                        cursor: default;
                    }
                    &:hover.sub-menu {
                        display: block;
                    }
                }
            }
        }
        div.top-menu.hamburg-submenu {
            position: absolute;
            top: 100%;
            left: 1px;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.4s;
            box-shadow: 2px 2px 3px #c0c0c0;
            width: 80%;
            min-width: 120px;
            max-width: 200px;
            background-color: white;
        }
        div.top-menu.hamburg-submenu.open-submenu {
            max-height: 600px;
        }
    }
    footer >div.legal >span {
        display: block;
    }
}

// Small devices (portrait tablets and large phones, 600px and up)
@media only screen and (min-width: 600px) {
    header#top-bar nav.topnav {
        div.pm-brand {
            flex: 0;
        }
        div.pmlogo-36x36 {
            margin-left: 10px;
        }
        div.top-menu {
            flex: 1;
            text-align: right;
            margin-right: 10px;
            white-space: nowrap;
            >ul {
                li {
                    position: relative;
                    display: inline-flex;
                    align-items: center;
                    text-align: center;
                    >a {
                        margin: auto 15px;
                        padding: 5px 2px;
                        border-bottom: 2px solid transparent;
                        &:hover {
                            border-color: #777;
                        }
                    }
                    div.arrow-down {
                        margin: auto 1px auto -10px;
                    }
                    ul.sub-menu {
                        position: absolute;
                        display: flex;
                        overflow: hidden;
                        flex-direction: column;
                        top: 34px;
                        left: 1px;
                        min-width: 120px;
                        max-width: 200px;
                        max-height: 0;
                        padding-inline-start: 0;
                        box-shadow: 2px 2px 3px #c0c0c0;
                        background-color: white;
                        transition: max-height 0.3s ease-out;
                        >li {
                            margin: 5px 0;
                            padding: 10px;
                            text-align: left;
                            width: 100%;
                            >a {
                                border-bottom: none;
                                &.selected {
                                    color: #58A55C;
                                }
                            }
                            &:hover {
                                background-color: #d7f1fd;
                            }
                        }
                    }
                    &:has(a.selected) >a {
                        border-color: #58A55C;
                    }
                }
            }
        }
        div.hamburg-menu {
            display: none;
        }
    }
}
