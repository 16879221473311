// Reference video about creating top navigation bar: https://www.youtube.com/watch?v=ybXulmeilFM

div.consent-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f0f0f0;
    box-shadow: 0 -2px 2px rgba(0, 0, 0 , 0.1);
    border-top: 1px solid #eaeaea;
    padding: 0 15px;
    z-index: 200;

    >div {
        display: flex;
        flex-wrap: wrap;
        max-width: 1170px;
        margin: 10px auto;
        justify-content: center;
        align-items: center;
        font-size: 12pt;

        p {
            color: #888888;
            font-size: inherit;
            text-align: center;
            >a {
                text-decoration: none;
                color: #0374f1;
                white-space: nowrap;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        button {
            font-size: inherit;
            width: 120px;
            height: 30px;
            margin: 10px;
            color: white;
            background-color: #0374f1;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            flex-shrink: 0;
        }
        button:hover {
            background-color: #0055aa;
        }
    }
}
div.consent-wrapper.hidden {
    display: none;
}

// Medium devices (landscape tablets, 768px and up)
@media only screen and (min-width: 768px) {
    div.consent-wrapper >div >p {
        flex: 1;
    }
}
