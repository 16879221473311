div#dpad-home-leaderboard {
    text-align: center;
    margin: 0 auto 10px auto;
}
div#dpad-home-skyscraper {
    margin: 1px auto auto 2px;
}
div.jackpot-list >div.dpad-history-tile {
    height: 200px;
    margin: 0 auto;
    padding: 0 20px 0 20px;
}
div#mpad-about-multipex {
    text-align: center;
}
div#dpad-stats-leaderboard {
    margin-bottom: 10px;
    text-align: center;
}
div.dpad-payoutmodal-banner {
    margin-top: 10px;
    text-align: center;
}
div.dpad-staticpage-banner {
    margin-bottom: 10px;
    text-align: center;
}
div.iaad-articlepage-bgnone {
    margin: 5px auto;
    text-align: center;
}
div#dpad-generator-left {
    margin: 0 10px 5px 0;
}
div#dpad-generator-right {
    margin: 0 0 5px 10px;
}
div.jackpot-list > div.jackpot-tile.dpad-winnings-tile {
    background-color: initial;
    border-color: transparent;
    padding: 0 30px;
}
div#dpad-winnings-left, div#dpad-winnings-right {
    padding-top: 10px;
}

div:has(> ins.adsbygoogle[data-ad-status='unfilled']) {
    display: none;
}

// Small devices (1107px and down)
@media only screen and (max-width: 1017px) {
    div.jackpot-list >div.dpad-history-tile {
        width: 294px;
    }
}

// Large devices (1108px and up)
@media only screen and (min-width: 1108px) {
    div.jackpot-list >div.dpad-history-tile {
        width: 322px;
    }
}
