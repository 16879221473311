div.lottery-balls {
    margin: 2px auto;
    width: 292px;
    color: #606060;
    display: flex;

    >.alphabet {
        min-width: 16px;
        margin: auto 4px auto 0px;
        font-size: 10pt;
        float: left;
    }
}
.ballframe {
    width: 34px;
    height: 34px;
    margin: 2px;
    border-radius: 50%;
    border: 2px solid transparent;
    &.selected-blue {
        border: 2px solid #0374f1;
    }
    &.selected-green {
        border: 2px solid #27e44a;
    }
}
.ball {
    text-align: center;
    vertical-align: middle;
    float: left;
    &.large {
        width: 32px;
        height: 32px;
        min-width: 32px;
        line-height: 32px;
        font-size: 12pt;
    }
    &.middle {
        width: 24px;
        height: 24px;
        min-width: 24px;
        line-height: 24px;
    }
    &.small {
        width: 18px;
        height: 18px;
        min-width: 18px;
        line-height: 18px;
        margin: 0px 2px;
    }
    &.circle {
        border-radius: 50%;
        border: 1px solid #404040;
        &.red {
            color: white;
            background-color: red;
        }
        &.yellow {
            background-color: yellow;
        }
        &.black {
            color: white;
            background-color: #303030;
        }
        &.white {
            background-color: white;
        }
    }
    &.mplier {
        font-size: 13pt;
        margin-top: 5px;
    }
}

// Large devices (laptops/desktops, 992px and up)
@media only screen and (min-width: 992px) {
    div.lottery-balls {
        margin: 3px auto;
        width: 320px;

        >.alphabet {
            font-size: 11pt;
        }
    }
    .ball {
        width: 34px;
        height: 34px;
        min-width: 34px;
        line-height: 34px;
        &.mplier {
            font-size: 14pt;
        }
    }
}
