div.stats-root {
    background-color: #f2f2f2;
    div.stats-box {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        max-width: 750px;
        margin: auto;
        >div.tbl-frame {
            >table {
                background-color: white;
                border: 1px solid #ccc;
                border-collapse: collapse;
                tr {
                    cursor: pointer;
                    td {
                        text-align: center;
                        border-top: 1px solid #ccc;
                        padding: 3px 0;
                        >div {
                            float: none;
                            display: inline-block;
                            &.right-arrow {
                                width: 12px;
                                height: 12px;
                                margin-right: 10px;
                                background-position: -400px -90px;
                            }
                        }
                        &.wider {
                            max-width: 100px;
                            width: 100px;
                        }
                        &.tprizes {
                            text-align: left;
                            padding: 5px 8px;
                        }
                        &.rank {
                            text-align: center;
                            background-color: #ddd;
                            padding: 5px 2px;
                            cursor: default;
                        }
                        &.highlight {
                            background-color: #ffcccc;
                        }
                    }
                    &:hover td:not(:first-child) {
                        background-color: #d1eff7;
                    }
                }
            }
            div.footnotes {
                margin: 20px 10px;
                max-width: 320px;
                font-size: 10pt;
                color: #868686;
            }
        }
        &.center {
            justify-content: center;
        }
    }
    div.empty-space {
        height: 30px;
    }
    div.top10-title {
        padding-top: 30px;
        font-size: 14pt;
        font-weight: bold;
        text-align: center;
    }
}

table.sortable {
    tr >th.sort {
        vertical-align: middle;
        padding: 3px 0;
        background-color: #ddd;
        font-size: 11pt;
        cursor: pointer;
        &.dcursor {
            cursor: default;
            height: 30px;
        }

        div.name {
            display: inline-block;
            margin-left: 5px;
            text-align: center;
            pointer-events: none;
            max-width: 60px;
        }
        i.icon {
            display: inline-block;
            border-style: solid;
            margin-right: 5px;
            pointer-events: none;

            &.up { border-width: 0px 5px 8px 5px }
            &.down { border-width: 8px 5px 0px 5px }
            &.active {
                visibility: visible;
                border-color: #666 transparent;
            }
            &.inactive {
                visibility: hidden;
                border-color: #aaa transparent;
            }
        }
        &:not(:last-child):hover {
            background-color: #e6e6e6;
            >i.icon { visibility: visible }
        }
    }
}

div.info-icon {
    width: 16px;
    height: 16px;
    margin-right: 6px;
    background-position: -440px -90px;
    cursor: help;

    &.upright {
        position: absolute;
        top: 40px;
        right: 20px;
    }
}

div.modal-bg > div.modal-popup > div.modal-content.stats {
    background-color: #f0f0f0;
    max-width: initial;
    div.ball-info {
        display: flex;
        align-items: center;
        justify-content: center;
        &.top {
            margin: 30px auto 5px auto;
        }
        &.bottom {
            margin: 5px auto 10px auto;
        }
        div.lblock {
            text-align: center;
            &.left {
                margin-right: 20px;
            }
            &.right {
                margin-left: 20px;
            }
        }
    }
}

div.modal-bg div.modal-popup div.modal-content {
    background-color: white;
}

// Extra small devices (phones, 400px and down)
@media only screen and (max-width: 400px) {
    div.stats-root >div.stats-box >div.tbl-frame {
        width: 100%;
        >table {
            width: 100%;
        }
    }
}

// Small devices (phones, 600px and down)
@media only screen and (max-width: 600px) {
}

// Small devices (phones, 683px and down)
@media only screen and (max-width: 683px) {
    div.stats-root >div.stats-box {
        :nth-child(2) {
            margin-top: 20px;
        }
    }
}

// Small devices (portrait tablets and large phones, 600px and up)
@media only screen and (min-width: 600px) {
}

// Medium devices (768px and down)
@media only screen and (max-width: 768px) {
    div.stats-root >div.stats-box {
        justify-content: center;
    }
}

// Medium devices (landscape tablets, 768px and up)
@media only screen and (min-width: 768px) {
}

// Large devices (laptops/desktops, 992px and down)
@media only screen and (max-width: 992px) {
}

// Large devices (laptops/desktops, 992px and up)
@media only screen and (min-width: 992px) {
}

// Extra large devices (large laptops and desktops, 1200px and up)
@media only screen and (min-width: 1200px) {
}
