div.static-root {
    font-family: 'Source Sans Pro', arial, helvetica, sans-serif;
    margin: 0 auto;

    div >li {
        padding: 5px 10px 5px 20px;
        line-height: 1.3;
    }
    div.htp-label {
        padding: 10px 10px 1px 10px;
    }
    div.htp-notes {
        padding: 2px 10px 5px 10px;
    }
    div.hscrollable {
        overflow-x: hidden;
    }
    div.column {
        width: 49%;
        display: inline-block;
    }
    div.align-right {
        float: right;
    }
    div.lottery-img {
        text-align: center;
        padding-top: 15px;

        img {
            width: 320px;
            height: 60px;
        }
    }

    table.tabular {
        border-collapse: collapse;
        margin: 0px;
        width: 100%;

        td, th {
            font-size: 16px;
            padding: 8px 10px;
            text-align: left;
        }
        tr.thmm th {
            border-top: 1px solid #D0D0D0;
        }
        tr.thpb th {
            border-top: 1px solid #505050;
        }
        tr.upperth th {
            padding: 8px 10px 1px 10px;
        }
        tr.lowerth th {
            padding: 1px 10px 8px 10px;
        }
    }

    table.tblmm {
        tr {
            background-color: #FAFAFA;
            th:first-child {
                border-left: 1px solid #D0D0D0;
            }
            th:last-child {
                border-right: 1px solid #D0D0D0;
            }
            td {
                border: 1px solid #D0D0D0;
            }
        }
        tr:hover {
            background-color: #DDDDDD;
        }
    }
    table.tblpb {
        tr {
            background-color: #413A35;
            th:first-child {
                border-left: 1px solid #505050;
            }
            th:last-child {
                border-right: 1px solid #505050;
            }
            td {
                border: 1px solid #505050;
            }
        }
        tr:hover {
            background-color: #3B3430;
        }
    }
}

div.static-root.new-features {
    div.nf-title {
        padding-top: 20px;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
    }
    div.nf-subtitle {
        padding-top: 5px 0 20px 0;
        font-size: 17px;
        text-align: center;
    }
    div.nf-label {
        font-size: 17px;
        font-weight: bold;
        padding: 30px 20px 0px 20px;
        text-decoration: underline;
    }
    div.nf-description {
        padding: 10px 20px;
        font-size: 18px;
    }
    div.nf-notes {
        padding: 10px 20px;
        font-size: 15px;
        font-style: italic;
        color: #606060;
        b {
            font-size: 17px;
            font-style: normal;
        }
    }
    div.nf-figure {
        padding-top: 6px;
        text-align: center;
        font-size: 16px;
        font-style: italic;
    }
    img.nf-images {
        margin: 0 20px;
        width: -webkit-calc(100% - 40px);
        width: -moz-calc(100% - 40px);
        width: calc(100% - 40px);
    }
}

.legal-page >p {
    >a {
        text-decoration: none;
        color: #0374f1;
        &:hover {
            text-decoration: underline;
        }
    }
    h1 {
        font-size: 32px;
        padding: 0px;
        margin-top: 10px;
    }
    h2, h3, h4 {
        padding: 0px;
        margin: 30px 0px 0px 0px;
    }
    h2 {font-size: 24px}
    h3 {font-size: 22px}
    h4 {font-size: 20px}
    p {
        font-size: 18px;
        margin: 10px 0px;
        line-height: 1.4;
        &.notes {
            font-style: italic;
            padding-bottom: 20px;
            color:#888888;
        }
    }
    li {
        font-size: 18px;
    }
}

// Extra small devices (phones, 400px and down)
@media only screen and (max-width: 400px) {
}

// Small devices (phones, 600px and down)
@media only screen and (max-width: 600px) {
    .legal-page {
       font-size: 16px;
       margin: 10px 10px 20px 10px;
       h1 {font-size: 30px}
       h2 {font-size: 22px}
       h3 {font-size: 20px}
       h4 {font-size: 18px}
    }
}

// Small devices (portrait tablets and large phones, 600px and up)
@media only screen and (min-width: 600px) {
    .legal-page {
        padding: 15px 15px 30px 15px;
    }
    div.static-root {
        padding: 0 20px;
        max-width: 560px;
        // background-color: white;
        img.nf-images {
            margin: 0 30px;
            width: calc(100% - 60px);
        }
    }
}

// Medium devices (landscape tablets, 768px and up)
@media only screen and (min-width: 768px) {
    .legal-page {
        padding: 20px 50px 30px 50px;
    }
}

// Large devices (laptops/desktops, 992px and up)
@media only screen and (min-width: 992px) {
    .legal-page {
       font-size: 20px;
       padding: 20px 80px 40px 80px;
       h1 {font-size: 36px;}
       h2 {font-size: 28px}
       h3 {font-size: 24px}
       h4 {font-size: 22px}
    }
    div.static-root {
        padding: 0 40px;
        max-width: 620px;
        img.nf-images {
            margin: 0 40px;
            width: calc(100% - 80px);
        }
    }
}

// Extra large devices (large laptops and desktops, 1200px and up)
@media only screen and (min-width: 1200px) {
    .legal-page {
        padding: 20px 120px 50px 120px;
        h1 {font-size: 30px}
        h2 {font-size: 22px}
        h3 {font-size: 20px}
        h4 {font-size: 18px}
    }
    div.static-root {
        padding: 0 50px;
        max-width: 700px;
        img.nf-images {
            margin: 0 50px;
            width: calc(100% - 100px);
        }
    }
}