div.home-root {
    padding: 10px;
    div.jackpot-container {
        display: flex;
        position: relative;
        margin: 0 auto 10px auto;
        justify-content: space-around;
        max-width: 800px;

        >div.jackpot-tile {
            margin: 0 2px;
            position: relative;
            display: inline-block;
            text-align: center;
            width: 360px;
            min-height: 500px;
            background-color: white;
            border: 1px solid #e0e0e0;
            font-size: 14pt;
            box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);

            >img {
                width: 240px;
                margin-top: 20px;
            }
            >div.label {
                text-align: left;
                color: #a0a0a0;
                margin-top: 20px;
                padding-left: 20px;
            }
            div.bmargin60 {
                width: 100%;
                height: 0px;
                margin-bottom: 70px;
            }
            div.btnbox {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                bottom: 15px;
                width: 100%;
                >button.left {
                    margin-right: 20px;
                }
                >button.right {
                    margin-left: 20px;
                }
            }
        }
    }
}
.fs-pt14 {
    font-size: 14pt;
}
.fs-pt12 {
    font-size: 13pt;
}
.fs-pt85 {
    font-size: 8.5pt;
}
.fs-pt75 {
    font-size: 7.5pt;
}
.fw-bold {
    font-weight: bold;
}

div.draw-info {
    width: 100%;
    margin: 3px 0;
    text-align: center;

    >.date {
        font-size: 14pt;
        padding: 20px 0 10px 0;
        min-height: 21.5px;
    }
    .hide {
        display: none;
    }
    >.winner {
        padding-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 19px;
        >div {
            display: inline-block;
        }
        >div.flash {
            width: 18px;
            height: 18px;
            background-position: -320px -90px;
            margin-right: 5px;
            display: none;
        }
    }
    >.lottery-balls {
        margin-top: 5px;
        justify-content: center;
        &.top-margin {
            margin-top: 15px;
        }
        &.bottom-margin {
            margin-bottom: 15px;
        }
        &.hide {
            display: none;
        }
    }

    div.half {
        width: 50%;
        display: inline-block;
        margin-top: 10px;
        .label {
            color: #808080;
            padding: 1px 10px;
            div {
                display: inline-block;
                width: 32px;
                padding: 0px;
            }
        }
        .value {
            padding: 1px 10px;
            white-space: nowrap;
            min-height: 23.5px;
            b {
                font-weight: bolder;
                &.red {
                    color: #eb3223;
                }
                &.green {
                    color: green;
                }
            }
            div {
                display: inline-block;
                width: 32px;
                padding: 0px;
            }
        }
        .left {
            text-align: left;
            padding: 1px 0px 1px 10px;
        }
        .right {
            text-align: right;
            padding: 1px 10px 1px 0px;
        }
        .center {
            text-align: center;
        }
    }
    .cd-element {
        display: inline-block;
        width: 50px;
        &.label {
            color: #808080;
        }
    }
    &.tborder {
        border-top: 1px solid #e0e0e0;
    }
}
// div.draw-info.tborder {
//     border-top: 1px solid #e0e0e0;
// }

div.poframe {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 10px;

    table:last-child {
        margin-bottom: 30px;
    }
}

table.payouts {
    margin: 10px;
    border-collapse: collapse;
    flex-grow: 1;
    tr {
        background-color: #FAFAFA;
        td, th {
            color: #505050;
            padding: 4px 8px;
            border: 1px solid #D0D0D0;
            >b.red {
                color: #eb3223;
            }
            >b.green {
                color: green;
            }
        }
        th {
            font-size: 10pt;
            font-weight: bold;
            text-align: center;
            padding: 6px 8px;
            border-top: 1px solid #D0D0D0;
            background-color: #DDDDDD;
            white-space: nowrap;
        }
    }
    tr:hover {
        background-color: #DDDDDD;
    }
}
div.dppayouts {
    margin-top: 20px;
    text-align: center;
}

// Extra small devices (phones, 400px and down)
@media only screen and (max-width: 400px) {
}

// Small devices (phones, 600px and down)
@media only screen and (max-width: 600px) {
    table.payouts {
        width: 100%;
        margin: 10px 2px;
        tr >td, tr >th {
            padding: 4px 2px;
        }
        .payout-balls {
            display: none;
        }
    }
    div.poframe {
        margin: 10px 0;
    }
}

// Small devices (portrait tablets and large phones, 600px and up)
@media only screen and (min-width: 600px) {
    table.payouts {
        .payout-match {
            display: none;
        }
    }
}

// Medium devices (768px and down)
@media only screen and (max-width: 768px) {
    div.home-root >div.jackpot-container {
        flex-wrap: wrap;
        >div.jackpot-tile {
            margin-bottom: 20px;
        }
    }
}

// Medium devices (landscape tablets, 768px and up)
@media only screen and (min-width: 768px) {
}

// Large devices (laptops/desktops, 992px and up)
@media only screen and (min-width: 992px) {
    div.home-root {
        div.jackpot-container {
            margin: 0 auto 10px auto;
            max-width: 990px;
            >div.jackpot-tile {
                width: 420px;
                min-height: 560px;
                font-size: 15.5pt;
                >img {
                    width: 260px;
                    margin-top: 25px;
                    &.powerball {
                        width: 220px;
                        margin: 25px auto 7.5px auto
                    }
                }
                >div.label {
                    margin-top: 30px;
                    padding-left: 30px;
                }
            }
        }
    }
    .fs-pt12 {
        font-size: 13.5pt;
    }
    .fs-pt85 {
        font-size: 9.5pt;
    }
    .fs-pt75 {
        font-size: 8.5pt;
    }
}
div.draw-info {
    >div.half {
        margin-top: 10px;
        .label div {
            width: 34px;
        }
        .value div {
            width: 34px;
        }
    }
}
