div.numbers-root > div.content-frame, div.winnings-root > div.content-frame, div.modal-popup > div.modal-content {
    margin: auto;
    max-width: 600px;
    div.tab {
        border: none;
        button {
            border-top: 1px solid #ccc;
            border-right: 1px solid #ccc;
            padding: 5px 15px;
            &:first-child {
                border-left: 1px solid #ccc;
            }
        }
        &.tabcontent {
            display: flex;
            padding: 10px 0px;
            border: 1px solid #ccc;
            background-color: white;
            justify-content: center;
            table tr td {
                padding: 4px 0;
                &.separator {
                    padding: 5px 0 0 0;
                    font-size: 9pt;
                    color: #a0a0a0;
                }
                &.duped {
                    background-color: #cec;
                }
                &.dupof {
                    background-color: #fbb;
                }
            }
        }
    }
    div.content-block {
        padding: 10px;
        margin: auto;
        min-width: 300px;
        max-width: 420px;
        min-height: calc(100vh - 250px);
        div.label-block {
            .first {
                margin-left: 130px;
            }
            .second {
                margin-left: 15px;
            }
        }
        div.controls {
            display: flex;
            padding: 10px 0px 6px 3px;
            margin-bottom: 10px;
            border-bottom: 1px solid #ccc;
            >.cta-frame {
                display: inline-flex;
                align-items: flex-end;
                button {
                    width: 80px;
                    padding: 5px 8px;
                    margin: auto 3px;
                }
                div.tooltip {
                    cursor: help;
                    &.lmargin-qp {
                        margin-left: 5px;
                    }
                    &.lmargin-mf {
                        margin-left: 88px;
                    }
                    &.lmargin2 {
                        margin-left: 14px;
                    }
                }
            }
            &.notoppad {
                padding-top: 0;
            }
            >.checkbox {
                margin: 5px 5px 5px 0;
                >input:checked ~ .checkmark:before {
                    content: attr(content-value);
                }
            }
        }
        div.tooptip:hover::before, button.confirm::before {
            content: attr(tipnotes);
            position: absolute;
            background-color: #505050;
            color: white;
            margin: 18px 0 0 -40px;
            padding: 5px 10px;
            border-radius: 5px;
            font-size: 11pt;
            z-index: 5;
        }
        div.section {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            margin: 15px auto;
            &.left {
                justify-content: left;
            }
            button.regular {
                border: 1px solid #ccc;
                width: 86px;
                padding: 5px 8px;
                margin: auto 2px;
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
                &.small {
                    padding: 5px 3px;
                    margin: auto 2px;
                }
            }
            label.mplier {
                margin-right: 4px;
                max-width: 72px;
                text-align: right;
            }
            .ball.large {
                margin: 0;
                cursor: pointer;
            }
            table.summary tr td {
                padding: 6px 2px;
                &:first-child {
                    width: 290px;
                    text-align: right;
                }
            }
        }
        div.footnotes {
            display: none;
            margin: 5px 0 15px 0;
            font-size: 9pt;
            color: #a0a0a0;
            >div {
                display: inline-block;
                width: 14px;
                // height: 18px;
                border: 1px solid #808080;
                &.red {
                    background-color: #faa;
                }
                &.green {
                    background-color: #cec;
                }
            }
        }
        table > tr > td > div.ball.large.circle {
            margin: 0 4px;
        }
    }
    .info-msg {
        padding: 30px 0 10px 0;
        text-align: center;
    }
}

div.numbers-root {
    div.action-bar, div.controls {
        display: flex;
        align-items: center;
        div.label-ctas {
            display: inline-block;
            margin-left: 10px;
            .label {
                color: #808080;
                padding: 1px 10px;
            }
            .cta-frame {
                display: flex;
                align-items: center;
                padding: 3px 0px;
            }
        }
        label.toggle-status {
            margin-right: 5px;
            &.tooltip:hover::before {
                content: attr(tipnotes);
                position: absolute;
                background-color: #505050;
                color: white;
                margin: 29px 0px 0px -15px;
                padding: 5px 10px;
                border-radius: 5px;
                font-size: 11pt;
                z-index: 2;
                white-space: nowrap;
            }
        }
    }
    button.regular {
        border:  1px solid #ccc;
        width: 60px;
        height: 30px;
        margin-right: 5px;
        &:not([disabled]):hover {
            color: white;
            background-color: #0374f1;
            border-color: #808080;
        }
    }
    >div.content-frame {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: initial;
        div.numgen-section {
            margin-bottom: 5px;
            div.content-block {
                width: 370px;
            }
        }
    }
}

div.winnings-root > div.content-frame {
    max-width: initial;
    div.summary-section {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        div.content-block {
            min-height: initial;
            margin: initial;
            div.info-icon {
                margin-left: 10px;
            }
            div.notes {
                margin-top: 0px;
                padding: 0 5px;
            }
        }
    }
}


// Extra small devices (phones, 400px and down)
@media only screen and (max-width: 400px) {
    div.numbers-root >div.content-frame div.content-block {
        padding: 10px 5px;
    }
}
