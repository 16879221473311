div.jackpot-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: auto;

    >div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.jackpot-tile {
            background-color: white;
            border: 1px solid #e0e0e0;
            margin: 5px auto;
            padding: 0 20px 10px 20px;
            cursor: pointer;
        }
    }
}

div.ltype-tab {
    text-align: center;
    padding: 20px 0 10px 0;
    margin: auto;
    >div.tab {
        display: inline-block;
        margin: auto;
        >button {
            width: 150px;
        }
    }
    >div.selections {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        >span {
            padding: 0 2px;
            &.rpad-28px {
                padding-right: 28px;
            }
        }
    }
}

// Small devices (phones, 600px and down)
@media only screen and (max-width: 700px) {
    div.jackpot-list {
        max-width: 380px;
    }
}

// Large devices (laptops/desktops, 1107px and down)
@media only screen and (max-width: 1107px) {
    div.jackpot-list {
        max-width: 750px;
    }
}

// Extra large devices (large laptops and desktops, 1108px and up)
@media only screen and (min-width: 1108px) {
    div.jackpot-list {
        max-width: 1108px;
    }
}
