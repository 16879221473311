body {
    font-family: arial, helvetica, sans-serif;
    font-size: 12pt;
    color: #505050;
    margin: 0px;
    padding: 0px;
    background-color: rgba(0,0,0,0.05);
    overflow-x: hidden;
}

//--------------------------------------------------------------
// images and logos cut out from web-logos.svg
//--------------------------------------------------------------
.logos {
    background: transparent url("../images/web-logos.svg") no-repeat;
}
.store-link {
    width: 135px;
    height: 40px;
    display: inline-block;
}
.store-link#apple-link {
    background-position: 0 -130px;
}
.store-link#google-link {
    background-position: 0 -180px;
}

//--------------------------------------------------------------
// container of the frame box that deals with authentication, registration, profile update, etc.
//--------------------------------------------------------------
div.container {
    margin: auto;
    width: 100%;
    height: auto;
    position: relative;

    div.frame-box {
        margin-top: 5px;
        background-color: #fefefe;
        border: 1px solid #b1b1b1;
        border-radius: 10px;
        min-height: 520px;

        >.close-btn {
            position: absolute;
            width: 24px;
            height: 24px;
            background-position: -80px -90px;
            top: 10px;
            right: 5px;
            cursor: pointer;
        }
        >.section {
            width: 100%;
            padding: 10px 0px;
            color: #808080;
            border-top: 1px solid #b1b1b1;

            .content {
                margin: 10px 5% 0px 5%;
                width: 90%;

                >p {
                    font-size: 13pt;
                    padding-bottom: 20px;
                    a {
                        text-decoration: none;
                        color: #0374f1;
                    }
                    a:hover {
                        text-decoration: underline;
                    }
                }
                >p#error-msg {
                    color: red;
                }
                div.form-msg {
                    margin: 20px auto 30px auto;
                }
                .label {
                    margin-top: 16px;
                    font-size: 10pt;
                    color: #888888;
                    span.set-password {
                        color: #ee3322;
                    }
                }
                .forgot-password {
                    margin-top: 5px;
                    width: 100%;
                    text-align: right;
                    display: inline-block;
                    position: relative;
                    font-size: 9pt;
                    color: #0374f1;
                    cursor: pointer;
                }
                .forgot-password:hover {
                    text-decoration: underline;
                }
                .inputbox {
                    font-size: 14pt;
                    margin-top: 2px;
                    padding: 5px;
                    width: 100%;
                    background-color: #fefefe;
                    border: 1px solid #b1b1b1;
                    box-sizing: border-box;
                }
                input:-webkit-autofill,
                input:-webkit-autofill:hover,
                input:-webkit-autofill:focus,
                input:-webkit-autofill:active {
                    font-size: 14pt !important;
                    box-shadow: 0 0 0 30px #fefefe inset !important;
                }
                .tooltip-input {
                    position: relative;
                }
                div.eye-icon {
                    position: absolute;
                    top: 6px;
                    right: 10px;
                    width: 24px;
                    height: 24px;
                    cursor: pointer;
                }
                div.eye-icon.open {
                    background-position: -160px -90px;
                }
                div.eye-icon.slash {
                    background-position: -200px -90px;
                }
                .dropdown {
                    font-size: 14pt;
                    margin: 2px 0px 10px 0px;
                    padding: 5px;
                    width: 100%;
                    box-sizing: border-box;
                    border: 1px solid #b1b1b1;
                    border-radius: 0;
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    appearance: none;
                    background-color: #ffffff;
                    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
                    background-repeat: no-repeat, repeat;
                    background-position: right .7em top 50%, 0 0;
                    background-size: .65em auto, 100%;
                    float: left;
                }
                div.login-msg {
                    overflow: auto;
                    margin: 10px 0px;
                    width: 100%;
                    height: auto;
                }
                div.login-msg.height60 {
                    min-height: 60px;
                }
                div.login-msg.error {
                    color: #ee3322;
                }
                div.login-msg.info {
                    color: #0374f1;
                }
                .agreement {
                    margin: 6px 0px;
                    .checkbox-box {
                        margin-top: 5px;
                        vertical-align: top;
                    }
                    span.checkbox-msg {
                        display: inline-block;
                        padding-left: 10px;
                        width: 300px;
                        a {
                            text-decoration: none;
                            color: #0374f1;
                        }
                        a:hover {
                            text-decoration: underline;
                        }
                    }
                }
                b.red-text {
                    color: #ee3322;
                }
            }
            div.store-links {
                position: relative;
                width: 100%;
                text-align: center;
                margin-top: 10px;
            }
            button.logos#apple-store-link {
                background-position: 0 -130px;
                border: 0px;
            }
            button.logos#google-store-link {
                background-position: -150px -130px;
            }
            button.storelink-button {
                cursor: pointer;
                width: 135px;
                height: 40px;
                margin: 5px 10px 0px 10px;
            }
            .separator {
                display: flex;
                position: relative;
                justify-content: center;
                margin: 0 20px;

                >.text {
                    text-align: center;
                    padding: 5px 15px;
                    z-index: 2;
                    background-color: #fefefe;
                }
            }
            .separator::after {
                content: "";
                display: block;
                border-bottom: 1px solid #b1b1b1;
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                z-index: 1;
            }
        }
        >.title {
            width: 100%;
            line-height: 40px;
            padding: 5px 0px;
            text-align: center;
            color: #888888;
            font-size: 16pt;
        }
        .button-frame {
            text-align: center;
            margin: 15px auto 20px auto;

            .submit-button {
                border:  1px solid #cccccc;
                min-width: 160px;
                width: 200px;
                height: 38px;
                text-align: center;
                font-size: 16px;
                margin: 1px;
                cursor: pointer;
                background-color: #f0f0f0;
            }
            .submit-button:enabled#register-button,
            .submit-button:enabled#update-button {
                background-color: #00aaff;
                color: white;
            }
            .submit-button:disabled#register-button,
            .submit-button:disabled#update-button {
                cursor: auto;
            }
            .submit-button:disabled:hover#register-button ~ .tooltiptext#checkbox-required,
            .submit-button:disabled:hover#update-button ~ .tooltiptext#checkbox-required {
                visibility: visible;
            }
            .delete-button {
                border:  1px solid #cccccc;
                min-width: 160px;
                width: 200px;
                height: 38px;
                text-align: center;
                font-size: 16px;
                margin: 20px auto 30px auto;
                cursor: pointer;
                color: #ffffff;
                background-color: #ec0606;
            }
            .login-options {
                border:  1px solid #cccccc;
                width: 260px;
                height: 38px;
                text-align: left;
                vertical-align: middle;
                font-size: 16px;
                margin: 1px;
                background-color: #f0f0f0;
                cursor: pointer;

                .login-text {
                    display: inline-block;
                    vertical-align: middle;
                    position: absolute;
                    line-height: 24px;
                    margin: 6px 10px auto 10px;
                }
                .logos#applogo24x24 {
                    width: 24px;
                    height: 24px;
                    margin: 6px 10px auto 10px;
                    background-position: 0 -50px;
                    display: inline-block;
                }
                .logos#google24x24 {
                    width: 24px;
                    height: 24px;
                    margin: 6px 10px auto 10px;
                    background-position: -40px -50px;
                    display: inline-block;
                }
                .logos#facebook24x24 {
                    width: 24px;
                    height: 24px;
                    margin: 6px 10px auto 10px;
                    background-position: -80px -50px;
                    display: inline-block;
                }
                .logos#apple24x24 {
                    width: 24px;
                    height: 24px;
                    margin: 6px 10px auto 10px;
                    background-position: -120px -50px;
                    display: inline-block;
                }
            }
        }
    }
}

//--------------------------------------------------------------
// Powermega logo, title and slogan
//--------------------------------------------------------------
#applogo48x48 {
    width: 48px;
    height: 48px;
    margin: 15px auto 0px auto;
    text-align: center;
    background-position: 0 0;
}
div.pm-brand {
    display: flex;
    align-items: center;
    justify-content: center;

    div.pmlogo-36x36 {
        width: 36px;
        height: 36px;
        background-position: -50px 0px;
    }
    img.pmlogo-48x48 {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin-right: 8px;
    }
    div.b-title {
        font-family: philosopher,sans-serif;
        font-weight: 900;
        font-size: 18px;
        line-height: 20px;
        padding: 7px 0px 0px 10px;
    }
    div.b-subtitle {
        font-size: 15px;
        font-weight: 300;
        line-height: 16px;
        padding: 0px 0px 7px 10px;
        white-space: nowrap;
    }
}
div.pm-brand.hide-element {
    display: none;
}

//--------------------------------------------------------------
// Tool tips
//--------------------------------------------------------------
div.tooltiptext {
    padding: 5px;
    width: 320px;
    color: #555555;
    background-color: #fff6e6;
    border: 1px solid #b1b1b1;
    border-radius: 6px;
    position: absolute;
    visibility: hidden;
    z-index: 1;
}
.tooltip-icon {
    display: inline;
    color: #8888ff;
    margin-left: 2px;
    cursor: help;
}
.tooltip-icon .tooltiptext {
    font-style: italic;
    margin-left: 12px;
}
.tooltip-icon:hover .tooltiptext {
  visibility: visible;
  margin-left: 15px;
}
.tooltip-input:hover .tooltiptext {
    visibility: visible;
    margin-left: 15px;
}
button#register-button + .tooltiptext,
button#update-button + .tooltiptext {
    font-style: italic;
    margin-top: -90px;
    margin-left: 15px;
}

//--------------------------------------------------------------
// spinning loader
//--------------------------------------------------------------
div.loader {
    border: 15px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #5ca24d;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    display: inline-block;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    z-index: 200;
}
// Safari
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

// Extra small devices (phones, 400px and down)
@media only screen and (max-width: 400px) {
    span.checkbox-msg {
        width: 240px;
    }
}

// Small devices (phones, 600px and down)
@media only screen and (max-width: 600px) {
    .tooltip-icon {
        display: none;
    }
    div.frame-box {
        margin-top: 0px;
        border-radius: 0px;
        min-height: 600px;
    }
}

// Small devices (portrait tablets and large phones, 600px and up)
@media only screen and (min-width: 600px) {
    div.container {
        width: 400px;
    }
    .content {
        margin: 10px 20px 0px 20px;
        width: 360px;
    }
    span.checkbox-msg {
        width: 330px;
    }
}

// Medium devices (landscape tablets, 768px and up)
@media only screen and (min-width: 768px) {
    div.container {
        width: 500px;
    }
}

// Large devices (laptops/desktops, 992px and up)
@media only screen and (min-width: 992px) {
}

// Extra large devices (large laptops and desktops, 1200px and up)
@media only screen and (min-width: 1200px) {
}
